import axios from "axios";
import { BASE_URL } from "../Utlils";

export const getMyQuestions = async (
  id,
  page,
  rows,
  questionType,
  searchText
) => {
  try {
    let encodedSearchText;
    if (searchText) {
      encodedSearchText = encodeURIComponent(searchText?.trim());
    }
    const { data } = await axios.get(
      `${BASE_URL}/questions/${id}?page=${
        page + 1
      }&rows=${rows}&questionType=${questionType}&searchText=${encodedSearchText}`
    );
    return data;
  } catch (error) {
    console.log("Unable to get my questions table data");
    throw error;
  }
};
export const addMyQuestions = async (data) => {
  console.log("data=>", data);
  try {
    let newQuestion = await axios.post(`${BASE_URL}/question`, data);
    return newQuestion.data;
  } catch (error) {
    console.log("Unable to add my question");
    throw error;
  }
};
export const getQuestionLibrary = async (
  page,
  rows,
  questionType,
  searchText
) => {
  try {
    let encodedSearchText;
    if (searchText) {
      encodedSearchText = encodeURIComponent(searchText?.trim());
    }
    const { data } = await axios.get(
      `${BASE_URL}/questionLibrary-all?page=${
        page + 1
      }&rows=${rows}&questionType=${questionType}&searchText=${encodedSearchText}`
    );
    return data;
  } catch (error) {
    console.log("Unable to get  question library");
    throw error;
  }
};
