import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MdDownload, MdDelete } from "react-icons/md";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../Utlils";
import { utils, writeFile } from "xlsx";
import DeleteModal from "../../components/DeleteModal";
import {
  getSingleSurveyGroup,
  getSurveysForSurveyGroup,
} from "./surveyGroupAction";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import NoData from "../../components/NoData";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    marginRight: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0226BE" : "#0226BE",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditSurveyGroup = () => {
  let { id } = useParams();
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate("");
  const { setSnackbar, organization } = useGlobalContext();
  const [inputData, setInputData] = useState({});
  const [selectRows, setSelectRows] = useState([]);
  const [debouncedSurveyNumberSearchText, setDebouncedSurveyNumberSearchText] =
    useState(inputData?.surveyNumberSearch || "");
  const {
    data: singleSurveyGroup,
    isLoading: singleSurveyGroupLoading,
    isError: singleSurveyGroupError,
  } = useQuery({
    queryKey: ["singleSurveyGroup", id],
    queryFn: async () => {
      const response = await getSingleSurveyGroup(id);
      setInputData({
        ...response,
        surveysTable: response?.surveys,
        surveys: response?.surveys?.map((d) => d?._id),
      });
      return response;
    },
  });
  const {
    data: allSurveys,
    isLoading: allSurveysLoading,
    isError: allSurveysError,
  } = useQuery({
    queryKey: [
      "survey-groups-surveys",
      organization?.id,
      debouncedSurveyNumberSearchText,
    ],
    queryFn: () =>
      getSurveysForSurveyGroup(
        organization?.id,
        debouncedSurveyNumberSearchText
      ),
    staleTime: 10000,
    cacheTime: 15000,
  });
  const handleCloseModal = (e) => setDeleteModal(false);
  //ADD
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = await axios.put(
        `${BASE_URL}/survey-group?id=${id}`,
        inputData
      );
      queryClient.invalidateQueries(["singleSurveyGroup"]);
      queryClient.invalidateQueries(["survey-groups"]);
      queryClient.invalidateQueries(["singleSurveyGroup"]);
      setSnackbar((prev) => ({
        open: true,
        msg: "Survey Group Updated !",
        severity: "success",
      }));
      navigate("/survey-groups");
    } catch (error) {
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      }));
    }
  };

  const handleMultiSelectMui = (e, value) => {
    setInputData((prev) => ({
      ...prev,
      surveys: [...prev?.surveys, ...value?.map((d) => d?._id)],
    }));
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  //DOWNLOAD TO EXCEL DATA:
  const DownloadToExcel = () => {
    if (selectRows?.length) {
      var wb = utils.book_new(),
        ws = utils.json_to_sheet(
          selectRows?.map((data) => {
            return {
              "Survey ID": data?.surveyNumber,
              "Surveys Name": data?.surveyName || "-",
              "Project Manager":
                data?.peoples?.projectManager?.personalDetails?.fullName || "-",
              Status: data?.surveyStatus || "-",
            };
          })
        );
      utils.book_append_sheet(wb, ws, "Sheet1");
      writeFile(wb, `Associated-Surveys.xlsx`);
      setSnackbar({
        open: true,
        severity: "success",
        msg: "Excel Sheet Downloaded!",
      });
      setSelectRows([]);
    } else {
      var elt = document.getElementById("table-to-xls-DATA");
      var wb = utils.table_to_book(elt, { sheet: "Sheet JS" });
      setSnackbar({
        open: true,
        severity: "success",
        msg: "Excel Sheet Downloaded!",
      });
      return writeFile(wb, `Associated-Surveys.xlsx`);
    }
  };

  //DELETE SURVEYS:
  const deleteSurveys = async (e, sgId, sid) => {
    e.preventDefault();
    try {
      let data = await axios.put(
        `${BASE_URL}/survey-group/remove-survey-from-surveyGroup?sgId=${sgId}&sid=${sid}`
      );
      setSnackbar((prev) => ({
        open: true,
        msg: "Survey Removed Successfully !",
        severity: "success",
      }));
      queryClient.invalidateQueries(["singleSurveyGroup"]);
      queryClient.invalidateQueries(["survey-groups"]);
      queryClient.invalidateQueries(["singleSurveyGroup"]);
    } catch (error) {
      // console.log(error);
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    } finally {
      setDeleteModal(false);
      setSelectRows([]);
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSurveyNumberSearchText(inputData?.surveyNumberSearch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputData?.surveyNumberSearch]);
  // console.log("inputData=>", inputData);
  // console.log({
  //   singleSurveyGroup,
  //   singleSurveyGroupLoading,
  //   singleSurveyGroupError,
  // });
  return (
    <>
      {singleSurveyGroupLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <span class="loader "></span>
        </div>
      ) : singleSurveyGroupError ? (
        <div className="w-full   flex items-center justify-center h-screen">
          <h1 className="text-[#e12121] text-[18px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
            Something went wrong !
          </h1>
        </div>
      ) : (
        <div className="w-full flex flex-col gap-[30px] py-6">
          <div className="w-full flex items-center justify-between ">
            <h1
              className="text-[#333] text-[26px] font-[500] inter flex items-center gap-[20px] cursor-pointer"
              onClick={(e) => navigate("/survey-groups")}
            >
              <AiOutlineArrowLeft /> Edit Survey Group Details
            </h1>
          </div>
          <div className="w-full flex items-center flex-col">
            <form
              className="w-full py-4   flex flex-col gap-[19px]"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="SurveyName"
                  className="text-[#333] text-[14px] font-[500]"
                >
                  Group Name
                </label>
                <input
                  type="text"
                  placeholder="abcd testing"
                  required
                  name="groupName"
                  onChange={handleChange}
                  value={inputData?.groupName}
                  className="border border-[#E2E2E5] text-[#12112766] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[14px]"
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="SurveyName"
                  className="text-[#333] text-[14px] font-[500]"
                >
                  Descriptions
                </label>
                <div className="flex w-full items-center relative">
                  <textarea
                    className="w-full border border-[#E2E2E5] rounded-[4px] pl-3 pt-2  placeholder:text-[14px]  text-[#12112766] focus:outline-none resize-none"
                    placeholder="Type here"
                    cols="30"
                    rows="4"
                    required
                    name="description"
                    onChange={handleChange}
                    value={inputData?.description}
                  ></textarea>
                </div>
              </div>
              <div className="flex items-center justify-between ">
                <label
                  htmlFor="SurveyName"
                  className="text-[#333] text-[14px] font-[500]"
                >
                  Is Active
                </label>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={inputData?.isActive}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          isActive: e.target.checked,
                        }))
                      }
                    />
                  }
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="SurveyName"
                  className="text-[#333] text-[14px] font-[500]"
                >
                  Select Survey
                </label>
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags"
                  required
                  loading={allSurveysLoading}
                  noOptionsText={
                    allSurveysLoading ? "Loading..." : "No Data found"
                  }
                  loadingText="Loading..."
                  options={
                    allSurveysLoading
                      ? []
                      : allSurveysError
                      ? []
                      : allSurveys?.data
                  }
                  getOptionLabel={(option) => option?.surveyNumber}
                  onChange={(a, b) => handleMultiSelectMui(a, b, b?._id)}
                  inputValue={inputData?.surveyNumberSearch}
                  onInputChange={(e, inputValue) =>
                    setInputData((prev) => ({
                      ...prev,
                      surveyNumberSearch: inputValue,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...inputData?.surveyNumberSearch}
                      type="string"
                      placeholder={"Enter Survey Number"}
                    />
                  )}
                  sx={{ width: "100%" }}
                />
              </div>
              <button
                type="submit"
                className="w-[100px] mt-3 self-end text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
              >
                Update
              </button>
            </form>
          </div>
          <h1 className="text-[#333] text-[26px] font-[500] inter flex items-center gap-[20px] ">
            Associated Surveys
          </h1>
          <div>
            {inputData?.surveysTable?.length ? (
              <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
                <div className="flex items-center gap-4">
                  <input
                    type="checkbox"
                    checked={selectRows?.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectRows(inputData?.surveysTable);
                      } else {
                        setSelectRows([]);
                      }
                    }}
                    className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
                  />
                  {selectRows?.length ? (
                    <span className="text-[15px] text-[#0226BE]">
                      Selected: ({selectRows?.length})
                    </span>
                  ) : (
                    <></>
                  )}

                  <MdDownload
                    size={24}
                    className="cursor-pointer"
                    onClick={(e) => {
                      DownloadToExcel();
                    }}
                  />
                  <MdDelete
                    size={24}
                    className="cursor-pointer"
                    onClick={(e) => {
                      if (selectRows?.length) {
                        setDeleteModal(true);
                      } else {
                        setSnackbar((prev) => ({
                          open: true,
                          msg: "Please Select at least one survey group to delete!",
                          severity: "warning",
                        }));
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="w-full tableBorder flex flex-col">
              <div className="w-full overflow-x-auto containerScroll">
                <table
                  className="whitespace-nowrap text-left w-full"
                  id="table-to-xls-DATA"
                >
                  <thead className=" h-[45px] ">
                    <tr className="py-3">
                      <th className="text-[#464545] text-[12px] font-[600] px-3"></th>
                      <th className="text-[#464545] text-[12px] font-[600] px-3">
                        Survey Id
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Survey Name
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3 ">
                        Project Manager
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Status
                      </th>
                      <th className="text-[#666] text-[12px] font-[600] px-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputData?.surveysTable?.length ? (
                      inputData?.surveysTable?.map((data, ind) => (
                        <tr className=" tableBorder transition-all  hover:bg-[#f6f9ff]">
                          <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                            <input
                              type="checkbox"
                              className="w-[15px] h-[15px] cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              checked={selectRows?.some(
                                (se) => se?._id == data?._id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectRows((prev) => [...prev, data]);
                                } else {
                                  setSelectRows(
                                    selectRows?.filter(
                                      (sc) => sc?._id !== data?._id
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.surveyNumber}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.surveyName || "-"}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.peoples?.projectManager?.personalDetails
                              ?.fullName || "-"}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.surveyStatus || "-"}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            <p className="cursor-pointer">
                              <MdDelete
                                color="#E97A7A"
                                size={19}
                                onClick={(e) => {
                                  setDeleteModal(true);
                                  setSelectRows([data]);
                                }}
                              />
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                            <NoData />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* DELETE MODAL */}
          <DeleteModal
            open={deleteModal}
            handleClose={() => {
              handleCloseModal();
              setSelectRows([]);
            }}
            text={selectRows?.map((data) => data?.surveyName)}
            handleClick={(e) => {
              if (selectRows?.length) {
                selectRows?.map((data) => {
                  deleteSurveys(e, inputData?._id, data?._id);
                });
              }
            }}
            oparationText={"Remove"}
          />
        </div>
      )}
    </>
  );
};

export default EditSurveyGroup;
