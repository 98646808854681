import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineSearch } from "react-icons/ai";
import {
  MdOutlineModeEditOutline,
  MdDownload,
  MdDelete,
  MdDeleteOutline,
} from "react-icons/md";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL, CustomTablePagination } from "../../Utlils";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { utils, writeFile } from "xlsx";
import DeleteModal from "../../components/DeleteModal";
import {
  addMyQuestions,
  getMyQuestions,
  getQuestionLibrary,
} from "../../Actions/questionsActions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import NoData from "../../components/NoData";
import Skeleton from "../../components/Skeleton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  borderRadius: 2,
};

const Qualification = () => {
  const queryClient = useQueryClient();
  let { countryData, setSnackbar, user, getMyQuestion } = useGlobalContext();
  const [tab, setTab] = useState(0);
  const [myQuestionsPage, setMyQuestionsPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState({});
  const [debouncedMyQuestionSearchText, setDebouncedMyQuestionSearchText] =
    useState(filters?.text);
  const [myQuestionsRowsPerPage, setMyQuestionsRowsPerPage] = useState(25);

  const {
    data: libraryQuestions,
    isLoading: libraryQuestionsLoading,
    isError: libraryQuestionsError,
  } = useQuery({
    queryFn: () =>
      getQuestionLibrary(
        page,
        rowsPerPage,
        filters?.type,
        debouncedMyQuestionSearchText
      ),
    queryKey: [
      "question-library",
      myQuestionsPage,
      myQuestionsRowsPerPage,
      filters?.type,
      debouncedMyQuestionSearchText,
    ],
    enabled: tab == 0,
  });
  const {
    data: myQuestions,
    isLoading: myQuestionsLoading,
    isError: myQuestionsError,
  } = useQuery({
    queryFn: () =>
      getMyQuestions(
        user?.organizationId,
        myQuestionsPage,
        myQuestionsRowsPerPage,
        filters?.type,
        debouncedMyQuestionSearchText
      ),
    queryKey: [
      "myQuestions",
      user?.organizationId,
      myQuestionsPage,
      myQuestionsRowsPerPage,
      filters?.type,
      debouncedMyQuestionSearchText,
    ],
    enabled: !!user?.organizationId && tab == 1,
  });
  const { mutateAsync: addNewQuestion } = useMutation({
    mutationFn: addMyQuestions,
    onSuccess: () => {
      queryClient.invalidateQueries(["myQuestions", user?.organizationId]);
    },
  });
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedMyQuestionSearchText(filters?.text);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filters?.text]);
  const [modalsOpen, setModalsOpen] = useState({
    preview: false,
    qualification: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectRows, setSelectRows] = useState([]);

  const [previewData, setPreviewData] = useState({});
  const [createQualiPage, setCreateQualiPage] = useState(0);

  const [qualifiOptions, setQualifiOptions] = useState(["", ""]);
  const [inputData, setInputData] = useState({});
  const handleCloseModal = (e) => setDeleteModal(false);
  const handleClose = (name) => {
    setModalsOpen((prev) => ({ ...prev, [name]: false }));
    setInputData({});
    setCreateQualiPage(0);
    setQualifiOptions(["", ""]);
  };

  useEffect(() => {
    setQualifiOptions(inputData?.options ?? ["", ""]);
  }, [inputData?.options]);

  const handleOpen = (name) =>
    setModalsOpen((prev) => ({ ...prev, [name]: true }));

  //ADD QUALIFICATION:
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (createQualiPage === 3) {
        let data = await addNewQuestion({
          ...inputData,
          options: qualifiOptions,
          createdBy: user?.organizationId,
        });
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Question Added Successfully ",
          severity: "success",
        }));
        handleClose("qualification");
        setInputData({});
        setQualifiOptions(["", ""]);

        setModalsOpen((prev) => ({ ...prev, qualification: false }));
      } else {
        setCreateQualiPage((prev) => prev + 1);
      }
    } catch (error) {
      setSnackbar((prev) => ({
        ...prev,
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      }));
    } finally {
      await getMyQuestion();
    }
  };

  //EDIT QUALIFICATION:
  const editQualification = async (e) => {
    e.preventDefault();
    try {
      if (createQualiPage == 3) {
        await axios
          .put(`${BASE_URL}/question?id=${inputData?._id}`, {
            ...inputData,
            options: qualifiOptions,
          })
          .then((res) => {
            queryClient.invalidateQueries([
              "myQuestions",
              user?.organizationId,
            ]);
            setSnackbar((prev) => ({
              ...prev,
              open: true,
              msg: "Question Updated Successfully ",
              severity: "success",
            }));
            setInputData({});
            setQualifiOptions(["", ""]);
            setSelectRows([]);
            handleClose("qualification");
          });
        setModalsOpen((prev) => ({ ...prev, qualification: false }));
      } else {
        setCreateQualiPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    }
  };

  const renderCreateQualificationPage = () => {
    switch (createQualiPage) {
      case 0:
        return (
          <>
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="QualificationName"
                className="text-[#333] text-[14px] font-[500]"
              >
                Qualification Name
              </label>
              <input
                type="text"
                required
                placeholder="Enter Qualification Name"
                value={inputData?.questionName}
                onChange={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    questionName: e.target.value,
                  }))
                }
                className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[12px] font-[400]"
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="QualificationType"
                className="text-[#333] text-[14px] font-[500]"
              >
                Qualification Type
              </label>
              <select
                name="QualificationType"
                id="QualificationType"
                required
                value={inputData?.questionType}
                onChange={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    questionType: e.target.value,
                  }))
                }
                className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#666] text-[12px] font-[400]"
              >
                <option value="" className="text-[#666] font-[400]">
                  Select Qualification Type
                </option>
                {[
                  "Multi Punch",
                  "Text - Open-end",
                  "Single Punch",
                  "Numeric - Open-end",
                ]?.map((data, ind) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
          </>
        );

      case 1:
        return (
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[10px]">
              <h1 className="text-[#000] font-[500] text-[14px]">
                Question Status
              </h1>
              <div className="flex w-[40%]  items-center justify-between">
                <div className="flex items-center gap-[15px] w-2/4">
                  <input
                    type="radio"
                    name="questionStatus"
                    required
                    id="active"
                    checked={inputData?.questionStatus === "active"}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        questionStatus: "active",
                      }))
                    }
                    className="accent-[#0226BE] w-[15px] h-[15px] "
                  />
                  <label
                    htmlFor="active"
                    className="text-[#666] text-[12px] font-[400]"
                  >
                    Active
                  </label>
                </div>
                <div className="flex items-center gap-[15px] w-2/4">
                  <input
                    type="radio"
                    name="questionStatus"
                    required
                    id="Inactive"
                    checked={inputData?.questionStatus === "inactive"}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        questionStatus: "inactive",
                      }))
                    }
                    className="accent-[#0226BE] w-[15px] h-[15px] "
                  />
                  <label
                    htmlFor="Inactive"
                    className="text-[#666] text-[12px] font-[400]"
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[10px]">
              <h1 className="text-[#000] font-[500] text-[14px]">
                Is Core Demographic
              </h1>
              <div className="flex w-[40%]  items-center justify-between">
                <div className="flex items-center gap-[15px] w-2/4">
                  <input
                    type="radio"
                    name="isCoreDemographic"
                    id="Yes"
                    required
                    checked={inputData?.isCoreDemographic}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        isCoreDemographic: true,
                      }))
                    }
                    className="accent-[#0226BE] w-[15px] h-[15px] "
                  />
                  <label
                    htmlFor="Yes"
                    className="text-[#666] text-[12px] font-[400]"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center gap-[15px] w-2/4">
                  <input
                    type="radio"
                    name="isCoreDemographic"
                    id="No"
                    required
                    checked={inputData?.isCoreDemographic === false}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        isCoreDemographic: false,
                      }))
                    }
                    className="accent-[#0226BE] w-[15px] h-[15px] "
                  />
                  <label
                    htmlFor="No"
                    className="text-[#666] text-[12px] font-[400]"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <>
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="Country"
                className="text-[#333] text-[14px] font-[500]"
              >
                Country
              </label>
              <input
                type="text"
                className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#666] text-[12px] font-[400]"
                list="countryList"
                placeholder="Enter Country Name"
                value={inputData?.country}
                onChange={(e) =>
                  setInputData((prev) => ({ ...prev, country: e.target.value }))
                }
              />
              <datalist id="countryList">
                <option value="" selected>
                  Select Country{" "}
                </option>
                {countryData?.isLoading ? (
                  <option value="" disabled>
                    Loading Countries...
                  </option>
                ) : countryData?.error ? (
                  <option value="" disabled>
                    Error while fetching countries try refreshing the page
                  </option>
                ) : (
                  countryData?.data?.map((data, key) => (
                    <option value={data?.name} key={key}>
                      {data?.name}
                    </option>
                  ))
                )}
              </datalist>
            </div>
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="language"
                className="text-[#333] text-[14px] font-[500]"
              >
                Select Language
              </label>
              <select
                name="language"
                id="language"
                value={inputData?.language}
                onChange={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    language: e.target.value,
                  }))
                }
                className="border border-[#E2E2E5] rounded-[4px] pl-2 h-[40px] focus:outline-none text-[#666] text-[12px] font-[400]"
              >
                <option value="" className="text-[#666] font-[400]">
                  Select Language
                </option>
                {["English", "French", "German", "Japanese", "Spanish"]?.map(
                  (data) => (
                    <option value={data}>{data}</option>
                  )
                )}
              </select>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="QualificationQuestionText"
                className="text-[#333] text-[14px] font-[500]"
              >
                Qualification Question Text
              </label>
              <textarea
                name="QualificationQuestionText"
                id="QualificationQuestionText"
                cols="30"
                rows="4"
                required
                value={inputData?.questionText}
                onChange={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    questionText: e.target.value,
                  }))
                }
                placeholder="Enter Qualification Question Text"
                className="border border-[#E2E2E5] rounded-[4px] pl-2  focus:outline-none text-[#666] text-[12px] font-[400] resize-none pt-3"
              ></textarea>
            </div>
            {["Multi Punch", "Single Punch"]?.includes(
              inputData?.questionType
            ) ? (
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="Country"
                  className="text-[#333] text-[14px] font-[500]"
                >
                  Option Text
                </label>
                <div className="flex flex-col gap-[12px] max-h-[150px] pr-[10px] overflow-y-auto containerScroll ">
                  {qualifiOptions?.map((data, ind, arr) => (
                    <div className="flex items-center relative" key={ind}>
                      <input
                        type="text"
                        required
                        onChange={(e) => {
                          const updatedOptions = [...qualifiOptions];
                          updatedOptions[ind] = e.target.value;
                          setQualifiOptions(updatedOptions);
                        }}
                        value={data}
                        placeholder={`Enter Option ${ind + 1}`}
                        className={`border border-[#E2E2E5] rounded-[4px] pl-2 py-[10px] focus:outline-none text-[#666] text-[12px] font-[400] ${
                          arr.length === 1 ? "w-full" : "w-[96%]"
                        } `}
                      />
                      <span
                        className={`absolute right-[0px] cursor-pointer ${
                          arr.length === 1 ? "hidden" : ""
                        }`}
                        onClick={(e) =>
                          setQualifiOptions((prev) =>
                            prev?.filter((_, inz) => inz !== ind)
                          )
                        }
                      >
                        <MdDeleteOutline color="red" size={18} />
                      </span>
                    </div>
                  ))}
                </div>
                <h3
                  className="text-[#0226BE] text-[12px] font-[400] cursor-pointer"
                  onClick={(e) => {
                    if (qualifiOptions) {
                      setQualifiOptions((prev) => [...prev, ""]);
                    } else {
                      setQualifiOptions((prev) => [""]);
                    }
                  }}
                >
                  + <span>Add More Options </span>
                </h3>
              </div>
            ) : (
              ""
            )}
          </>
        );
      default:
        <h1>No Data</h1>;
    }
  };

  //GENERATE EXCEL FILE:
  const [xlData, setXlData] = useState([]);
  const generateExcelFile = (orgData) => {
    let generateData = [];
    orgData?.forEach((data) => {
      const options = data?.options?.map((d) => `${d}\n`).join(" ");
      generateData.push({
        "Qualification Name": data?.questionName || "-",
        Question: data?.questionText,
        "Qualification Type": data?.questionType,
        Category: "Custom",
        Visibility: "Public",
        "Current Usage": 0,
        Status: data?.questionStatus,
        Options: options,
      });
    });
    setXlData(generateData);
  };

  useEffect(() => {
    if (selectRows?.length) {
      generateExcelFile(selectRows);
    } else {
      generateExcelFile(myQuestions?.data);
    }
  }, [selectRows?.length]);

  //DOWNLOAD TO EXCEL DATA:
  const DownloadToExcel = () => {
    var wb = utils.book_new(),
      ws = utils.json_to_sheet(xlData);
    utils.book_append_sheet(wb, ws, "Sheet1");
    setSelectRows([]);
    writeFile(wb, `Questions.xlsx`);
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  // pagination function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage2 = (event, newPage) => {
    setMyQuestionsPage(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setMyQuestionsRowsPerPage(parseInt(event.target.value, 10));
    setMyQuestionsPage(0);
  };
  //DELETE QUESTION:
  const deleteQuestion = async (e, id) => {
    e.preventDefault();
    try {
      await axios.delete(`${BASE_URL}/question?id=${id}`).then(() => {
        queryClient.invalidateQueries(["myQuestions", user?.organizationId]);
        setSelectRows([]);
        setDeleteModal(false);

        setSnackbar({
          open: true,
          severity: "success",
          msg: "Question Deleted Successfully!",
        });
      });
    } catch (error) {
      console.log("Unable to delete question");
      setSelectRows([]);
      setDeleteModal(false);
      setSnackbar({
        open: true,
        severity: "error",
        msg: "Something went wrong!",
      });
    }
  };

  // console.log(qualifiOptions);
  // console.log(myQuestions, myQuestionsLoading, myQuestionsError);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter">
          Qualification Library
        </h1>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]"
          onClick={(e) => handleOpen("qualification")}
        >
          Create New Qualification
        </button>
      </div>
      {/* filters row */}
      <div className="flex items-center justify-between py-3">
        <div className="flex items-center gap-5">
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none"
            value={filters?.type || ""}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, type: e.target.value }))
            }
          >
            <option value="">All Question Type</option>
            {[
              "Single Punch",
              "Multi Punch",
              "Numeric - Open-end",
              "Text - Open-end",
            ]?.map((d, key) => (
              <option value={d} key={key}>
                {d}
              </option>
            ))}
          </select>
          <p
            className="text-[14px] underline underline-offset-4 cursor-pointer"
            onClick={(e) => setFilters({})}
          >
            Clear filters
          </p>
        </div>
        <div className="w-[30%] relative">
          <input
            type="text"
            className=" w-full border border-[#E0E0E0] rounded-[8px] focus:outline-none h-[40px] pl-12 text-[14px] "
            placeholder="Search Question Text"
            value={filters?.text || ""}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, text: e.target.value }))
            }
          />
          <span className="absolute top-3 left-4">
            <AiOutlineSearch size={18} />
          </span>
        </div>
      </div>
      {/* table  */}
      <div>
        <div className="w-full tableBorder flex items-center gap-[60px] px-5 py-3 mt-[5px]">
          {["Question Library", "My Questions"]?.map((data, ind) => (
            <h1
              key={ind}
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
              }}
            >
              {data}
            </h1>
          ))}
        </div>
        {tab == 1 && selectRows?.length ? (
          <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
                checked={selectRows?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectRows(myQuestions?.data);
                  } else {
                    setSelectRows([]);
                  }
                }}
              />
              {selectRows?.length ? (
                <span className="text-[15px] text-[#0226BE]">
                  Selected: ({selectRows?.length})
                </span>
              ) : (
                <></>
              )}
              {selectRows?.length == 1 ? (
                <MdOutlineModeEditOutline
                  size={24}
                  className="cursor-pointer"
                  onClick={(e) => {
                    if (selectRows?.length && selectRows?.length == 1) {
                      handleOpen("qualification");
                      setInputData({
                        edit: true,
                        ...selectRows?.[0],
                      });
                    } else {
                      setSnackbar((prev) => ({
                        open: true,
                        msg: `Please Select at least one qualification to edit!`,
                        severity: "warning",
                      }));
                    }
                  }}
                />
              ) : (
                ""
              )}
              <MdDownload
                size={24}
                onClick={(e) => {
                  DownloadToExcel();
                }}
                className="cursor-pointer"
              />
              <MdDelete
                size={24}
                className="cursor-pointer"
                onClick={(e) => {
                  setDeleteModal(true);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  <th className="text-[#464545] text-[12px] font-[600] px-3"></th>
                  <th className="text-[#464545] text-[12px] font-[600] px-3">
                    Qualification Name
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Question
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3 ">
                    Qualification Type
                  </th>

                  {/* <th className="text-[#666] text-[12px] font-[600] px-3">
                      Current Usage
                    </th> */}
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Status
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Preview
                  </th>
                </tr>
              </thead>
              {tab === 0 ? (
                <tbody>
                  {libraryQuestionsLoading ? (
                    <>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((d, i) => (
                        <tr key={i}>
                          <td className="p-3 text-[#667085]  ">
                            <Skeleton className="w-[50px] h-[30px] rounded-md" />
                          </td>
                          {[1, 2, 3, 4, 5]?.map((dd, ii) => (
                            <td className="p-3 ">
                              <Skeleton
                                key={ii}
                                className="w-[170px] h-[30px] rounded-md"
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : libraryQuestionsError ? (
                    <tr>
                      <td colSpan={6}>
                        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                          <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                            Something went wrong !
                          </h1>
                        </div>
                      </td>
                    </tr>
                  ) : libraryQuestions?.data?.length ? (
                    libraryQuestions?.data?.map((data, ind) => (
                      <tr
                        className=" tableBorder transition-all  hover:bg-[#f6f9ff]"
                        key={ind}
                      >
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          {tab == 1 ? (
                            <input
                              type="checkbox"
                              className="w-[15px] h-[15px] cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              checked={selectRows?.some(
                                (se) => se?._id == data?._id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectRows((prev) => [...prev, data]);
                                } else {
                                  setSelectRows(
                                    selectRows?.filter(
                                      (sc) => sc?._id !== data?._id
                                    )
                                  );
                                }
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          {data?.questionName}
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p className=" max-w-[400px] break-words  whitespace-break-spaces">
                            {data?.questionText}
                          </p>
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          {data?.questionType}
                        </td>

                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p className="bg-[#EDFBF4] rounded-[11px] w-[71px] h-[30px] flex items-center justify-center text-[#2FBB85] inter text-[12px] capitalize">
                            {data?.questionStatus}
                          </p>
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p
                            className="cursor-pointer"
                            onClick={(e) => {
                              handleOpen("preview");
                              setPreviewData(data);
                            }}
                          >
                            {" "}
                            <AiOutlineEye size={22} />
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                          <NoData msg={"Try Changing the filters"} />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {myQuestionsLoading ? (
                    <>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((d, i) => (
                        <tr key={i}>
                          <td className="p-3 text-[#667085]  ">
                            <Skeleton className="w-[50px] h-[30px] rounded-md" />
                          </td>
                          {[1, 2, 3, 4, 5]?.map((dd, ii) => (
                            <td className="p-3 ">
                              <Skeleton
                                key={ii}
                                className="w-[170px] h-[30px] rounded-md"
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : myQuestionsError ? (
                    <tr>
                      <td colSpan={6}>
                        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                          <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                            Something went wrong !
                          </h1>
                        </div>
                      </td>
                    </tr>
                  ) : myQuestions?.data?.length ? (
                    myQuestions?.data?.map((data, ind) => (
                      <tr
                        className=" tableBorder transition-all  hover:bg-[#f6f9ff]"
                        key={ind}
                      >
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <input
                            type="checkbox"
                            className="w-[15px] h-[15px] cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            checked={selectRows?.some(
                              (se) => se?._id == data?._id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectRows((prev) => [...prev, data]);
                              } else {
                                setSelectRows(
                                  selectRows?.filter(
                                    (sc) => sc?._id !== data?._id
                                  )
                                );
                              }
                            }}
                          />
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          {data?.questionName || "-"}
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p className=" max-w-[400px] break-words  whitespace-break-spaces">
                            {data?.questionText}
                          </p>
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          {data?.questionType}
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p className="bg-[#EDFBF4] rounded-[11px] w-[71px] h-[30px] flex items-center justify-center text-[#2FBB85] inter text-[12px] capitalize">
                            {data?.questionStatus}
                          </p>
                        </td>
                        <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                          <p
                            className="cursor-pointer"
                            onClick={(e) => {
                              handleOpen("preview");
                              setPreviewData(data);
                            }}
                          >
                            {" "}
                            <AiOutlineEye size={22} />
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                          <NoData
                            msg={
                              <h1
                                className="text-primary font-[500] underline underline-offset-4 decoration-2 cursor-pointer"
                                onClick={(e) => handleOpen("qualification")}
                              >
                                Add new
                              </h1>
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {tab === 0 ? (
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  sx={{ fontSize: "10px" }}
                  count={libraryQuestions?.pagination?.totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  componentsProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  sx={{ fontSize: "10px" }}
                  count={myQuestions?.pagination?.totalRecords}
                  rowsPerPage={myQuestionsRowsPerPage}
                  page={myQuestionsPage}
                  componentsProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage2}
                  onRowsPerPageChange={handleChangeRowsPerPage2}
                />
              )}
            </table>
          </div>
        </div>
      </div>
      {/* preview modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalsOpen?.preview}
        onClose={(e) => handleClose("preview")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalsOpen?.preview}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Question Preview
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => handleClose("preview")}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <div className="w-full  p-5 flex flex-col gap-[20px]">
                <h1 className="text-[#000] text-[20px] font-[500] inter">
                  {previewData?.questionText}
                </h1>

                {previewData?.questionType == "Multi Punch" ||
                previewData?.questionType == "Single Punch" ? (
                  <div className="w-full  max-h-[250px] overflow-y-auto containerScroll flex flex-col gap-[8px]">
                    {previewData?.options?.map((data, ind) => (
                      <div
                        className="w-full pr-2 flex items-center gap-[12px] my-2 "
                        key={ind}
                      >
                        {previewData?.questionType == "Multi Punch" ? (
                          <input
                            type="checkbox"
                            name={data}
                            id={data}
                            className="w-[18px] h-[18px]"
                          />
                        ) : (
                          <input
                            type="radio"
                            name="singlePunchOption"
                            id={data}
                            className="w-[18px] h-[18px]"
                          />
                        )}

                        <label
                          htmlFor={data}
                          className="text-[16px] font-[400] text-[#333] w-[90%] cursor-pointer capitalize"
                        >
                          {data}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <input
                    type={`${
                      previewData?.questionType == "Numeric - Open-end"
                        ? "number"
                        : "text"
                    }`}
                    step="any"
                    placeholder="Add Text"
                    className="w-[60%] border border-[#E0E0E0]  rounded-[6.5px] pl-3 text-[#666] font-[400] text-[12px] h-[40px] mt-3 bg-[#F9F9FF]"
                  />
                )}
                {}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* create modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalsOpen?.qualification}
        onClose={(e) => handleClose("qualification")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalsOpen?.qualification}>
          <Box sx={{ ...style, width: 600 }}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  pl-5 relative">
                <h1 className="text-[#333333] text-[20px] font-[500]">
                  {inputData?.edit ? "Edit" : "Create"} Qualifications
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => handleClose("qualification")}
                >
                  <RxCross2 size={22} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[30px]"
                onSubmit={inputData?.edit ? editQualification : handleSubmit}
              >
                {renderCreateQualificationPage()}
                <div className="flex items-center justify-center gap-[20px]">
                  <button
                    onClick={(e) => setCreateQualiPage((prev) => prev - 1)}
                    type="button"
                    className={`text-[#0226BE] self-center  inter text-[16px] font-[400] border border-[#0226BE] rounded-[4px] py-[8px]  px-[16px] ${
                      createQualiPage < 1 ? "hidden" : ""
                    }`}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className={`text-[#fff] self-center  inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-[8px]  px-[16px] border border-[#0226BE]`}
                  >
                    {createQualiPage === 3 ? "Finish" : "Next"}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* DELETE MODAL */}
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseModal}
        text={
          selectRows?.length
            ? selectRows?.map((data) => data?.questionName)
            : myQuestions?.data?.map((data) => data?.questionName)
        }
        handleClick={(e) => {
          if (selectRows?.length) {
            selectRows?.map((data) => {
              deleteQuestion(e, data?._id);
            });
          } else {
            myQuestions?.data?.map((data) => {
              deleteQuestion(e, data?._id);
            });
          }
        }}
      />
    </div>
  );
};

export default Qualification;
