import React, { useEffect, useState } from "react";
import TotalSurveyGroup from "../../assets/icons/TotalSurveyGroup.svg";
import TotalActiveSurveyGroup from "../../assets/icons/ToatalActiveSurveyGroup.svg";
import TotalInactiveSurveyGroup from "../../assets/icons/TotalInactiveSurveyGroup.svg";
import { AiOutlineSearch } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MdDownload, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL, CustomTablePagination } from "../../Utlils";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import { utils, writeFile } from "xlsx";
import DeleteModal from "../../components/DeleteModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSurveyGroups, getSurveysForSurveyGroup } from "./surveyGroupAction";
import Skeleton from "../../components/Skeleton";
import { Tooltip } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    marginRight: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0226BE" : "#0226BE",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SurveyGroup = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selectRows, setSelectRows] = useState([]);
  const [filters, setFilters] = useState("");
  const [debouncedSurveyGroupFilterText, setDebouncedSurveyGroupFilterText] =
    useState(filters || "");
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { user, setSnackbar, organization } = useGlobalContext();
  const [inputData, setInputData] = useState({ isActive: true });
  const [debouncedSurveyNumberSearchText, setDebouncedSurveyNumberSearchText] =
    useState(inputData?.surveyNumberSearch || "");
  const {
    data: allSurveyGroups,
    isLoading: allSurveyGroupsLoading,
    isError: allSurveyGroupsError,
  } = useQuery({
    queryKey: [
      "survey-groups",
      organization?.id,
      page,
      rowsPerPage,
      debouncedSurveyGroupFilterText,
    ],
    queryFn: () =>
      getSurveyGroups(
        organization?.id,
        page,
        rowsPerPage,
        debouncedSurveyGroupFilterText
      ),
    staleTime: 10000,
    cacheTime: 15000,
  });

  const {
    data: allSurveys,
    isLoading: allSurveysLoading,
    isError: allSurveysError,
  } = useQuery({
    queryKey: [
      "survey-groups-surveys",
      organization?.id,
      debouncedSurveyNumberSearchText,
    ],
    queryFn: () =>
      getSurveysForSurveyGroup(
        organization?.id,
        debouncedSurveyNumberSearchText
      ),
    staleTime: 10000,
    cacheTime: 15000,
    enabled: open,
  });
  useEffect(() => {
    if (filters !== debouncedSurveyGroupFilterText) {
      const handler = setTimeout(() => {
        setDebouncedSurveyGroupFilterText(filters);
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    } else {
      const handler = setTimeout(() => {
        setDebouncedSurveyNumberSearchText(inputData?.surveyNumberSearch);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [inputData?.surveyNumberSearch, filters]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };
  const handleCloseModal = (e) => setDeleteModal(false);

  //ADD SURVEY GROUP:
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/survey-group`, {
        ...inputData,
        createdBy: user?.organizationId,
      })
      .then((res) => {
        queryClient.invalidateQueries(["survey-groups", organization?.id]);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Survey Group Added !",
          severity: "success",
        }));
        setOpen(false);
        setInputData({ isActive: true });
      })
      .catch((er) => {
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Something went wrong !",
          severity: "error",
        }));
        setOpen(false);
      });
  };

  const handleMultiSelectMui = (e, value) => {
    setInputData((prev) => ({ ...prev, surveys: value?.map((d) => d?._id) }));
  };

  //DOWNLOAD TO EXCEL DATA:
  const DownloadToExcel = () => {
    if (allSurveyGroups?.data?.length) {
      if (selectRows?.length) {
        var wb = utils.book_new(),
          ws = utils.json_to_sheet(
            selectRows?.map((data) => {
              const surveyNumbers = data?.surveys
                ?.map((d) => `QID#${d?.surveyNumber}`)
                .join(" ");

              return {
                "Group Name": data?.groupName,
                Description: data?.description,
                Surveys: surveyNumbers,
                Status: data?.isActive ? "Active" : "Inactive",
              };
            })
          );
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, `Survey-Group-Data.xlsx`);
        setSnackbar({
          open: true,
          severity: "success",
          msg: "Excel Sheet Downloaded!",
        });
        setSelectRows([]);
      } else {
        var elt = document.getElementById("table-to-xls-DATA");
        var wb = utils.table_to_book(elt, { sheet: "Sheet JS" });
        setSnackbar({
          open: true,
          severity: "success",
          msg: "Excel Sheet Downloaded!",
        });
        return writeFile(wb, `Survey-Group-Data.xlsx`);
      }
    } else {
      setSnackbar({
        open: true,
        severity: "warning",
        msg: "No Data to Download!",
      });
    }
  };

  // DELETE SURVEYS:
  const deleteSurveyGroups = async (e) => {
    e.preventDefault();
    try {
      selectRows?.forEach(async (data) => {
        await axios
          .delete(`${BASE_URL}/survey-group/remove/${data?._id}`)
          .then(() => {
            queryClient.invalidateQueries(["survey-groups", organization?.id]);
            setDeleteModal(false);
            setSnackbar((prev) => ({
              open: true,
              msg: "Survey group Details Deleted Successfully !",
              severity: "success",
            }));
          });
      });
    } catch (error) {
      setDeleteModal(false);
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    } finally {
      setSelectRows([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // console.log("allSurveyGroups=>", allSurveyGroups);
  // console.log("inputData=>", inputData);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter">
          Survey Groups
        </h1>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]"
          onClick={handleOpen}
        >
          Create Survey Group
        </button>
      </div>
      {/* survey card */}
      <div className="w-full grid grid-cols-5 gap-10">
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalSurveyGroup} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {allSurveyGroups?.pagination?.totalRecords || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Survey Group
          </p>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img
            src={TotalActiveSurveyGroup}
            alt=""
            className="w-[26px] h-[27px]"
          />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {allSurveyGroups?.data?.filter((d) => d?.isActive)?.length || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Active Survey Group
          </p>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img
            src={TotalInactiveSurveyGroup}
            alt=""
            className="w-[26px] h-[27px]"
          />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {allSurveyGroups?.data?.filter((d) => !d?.isActive)?.length || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Inactive Survey Group
          </p>
        </div>
      </div>
      {/* filters row */}
      <div className="flex items-center  py-3 gap-[20px] w-[100%]  justify-between">
        {/* <select className="border border-[#E0E0E0] h-[40px] px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none">
          <option value="">Survey Type</option>
        </select> */}
        <div className="w-[60%]"></div>
        <div className="w-[30%] relative flex ">
          <div className="w-full self-end justify-self-end">
            <input
              type="text"
              className=" w-full border border-[#E0E0E0] rounded-[8px] focus:outline-none h-[40px] pl-12 text-[14px] "
              placeholder="Search"
              onChange={(e) => setFilters(e.target.value)}
            />
            <span className="absolute top-3 left-4">
              <AiOutlineSearch size={18} />
            </span>
          </div>
        </div>
      </div>
      {/* table  */}
      <div>
        <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
              checked={selectRows?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectRows(allSurveyGroups?.data);
                } else {
                  setSelectRows([]);
                }
              }}
            />
            {selectRows?.length ? (
              <span className="text-[15px] text-[#0226BE]">
                Selected: ({selectRows?.length})
              </span>
            ) : (
              <></>
            )}

            <MdDownload
              size={24}
              className="cursor-pointer"
              onClick={(e) => {
                DownloadToExcel();
              }}
            />
            <MdDelete
              size={24}
              className="cursor-pointer"
              onClick={(e) => {
                if (selectRows?.length) {
                  setDeleteModal(true);
                } else {
                  setSnackbar((prev) => ({
                    open: true,
                    msg: "Please Select at least one survey group to delete!",
                    severity: "warning",
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  <th className="text-[#464545] text-[12px] font-[600] px-3"></th>
                  <th className="text-[#464545] text-[12px] font-[600] px-3">
                    Group Name
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Description
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3 ">
                    Surveys
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Status
                  </th>
                </tr>
              </thead>

              <tbody>
                {allSurveyGroupsLoading ? (
                  <>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((d, i) => (
                      <tr key={i}>
                        <td className="p-3 text-[#667085]  ">
                          <Skeleton className="w-[50px] h-[30px] rounded-md" />
                        </td>
                        {[1, 2, 3, 4]?.map((dd, ii) => (
                          <td className="p-3 ">
                            <Skeleton
                              key={ii}
                              className="w-[170px] h-[30px] rounded-md"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : allSurveyGroupsError ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                        <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                          Something went wrong !
                        </h1>
                      </div>
                    </td>
                  </tr>
                ) : allSurveyGroups?.data?.length ? (
                  allSurveyGroups?.data?.map((data, ind) => (
                    <tr
                      className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                      onClick={(e) => navigate(`${data?._id}`)}
                    >
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        <input
                          type="checkbox"
                          className="w-[15px] h-[15px] cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          checked={selectRows?.some(
                            (se) => se?._id == data?._id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectRows((prev) => [...prev, data]);
                            } else {
                              setSelectRows(
                                selectRows?.filter(
                                  (sc) => sc?._id !== data?._id
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        <Tooltip title={data?.groupName}>
                          <h1 className="w-[150px]  overflow-hidden whitespace-nowrap text-ellipsis ">
                            {data?.groupName}
                          </h1>
                        </Tooltip>
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        <p className="w-[300px]  text-wrap ">
                          {data?.description}
                        </p>
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        <div className="w-[400px]  text-wrap flex flex-wrap gap-[10px]">
                          {data?.surveys?.map((sdata) => (
                            <h1 className="bg-gray-200 py-1 px-2 rounded-md">
                              {" "}
                              QID# {sdata?.surveyNumber}
                            </h1>
                          ))}
                        </div>
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        {data?.isActive ? (
                          <div className="px-[12px] py-[4px] bg-[#EDFBF4] text-[#2FBB85] text-[12px] font-[500]  rounded-[11px] text-center">
                            Active
                          </div>
                        ) : (
                          <div className="px-[12px] py-[4px] bg-[#FFFAF1] rounded-[11px] text-[#CB8A14] text-[12px] font-[500] text-center">
                            Inactive
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                        <NoData />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                sx={{ fontSize: "10px" }}
                count={allSurveyGroups?.pagination?.totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                componentsProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </table>
          </div>
        </div>
      </div>
      {/* create modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Add survey group details
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={handleClose}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="SurveyName"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Group Name
                  </label>
                  <input
                    type="text"
                    required
                    name="groupName"
                    onChange={handleChange}
                    value={inputData?.groupName}
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px]"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="description"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Descriptions
                  </label>
                  <div className="flex w-full items-center relative">
                    <textarea
                      className="w-full border border-[#E2E2E5] rounded-[4px] pl-3 pt-2  placeholder:text-[14px] placeholder:text-[#12112766] focus:outline-none resize-none text-[#666] text-[14px]"
                      placeholder="Type here"
                      cols="30"
                      rows="4"
                      required
                      name="description"
                      onChange={handleChange}
                      value={inputData?.description}
                    ></textarea>
                  </div>
                </div>
                <div className="flex items-center justify-between ">
                  <label
                    htmlFor="SurveyName"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Is Active
                  </label>

                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked={inputData?.isActive}
                        onChange={(e) =>
                          setInputData((prev) => ({
                            ...prev,
                            isActive: e.target.checked,
                          }))
                        }
                      />
                    }
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="SurveyName"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Select Survey
                  </label>
                  <Autocomplete
                    multiple
                    limitTags={3}
                    id="multiple-limit-tags"
                    required
                    loading={allSurveysLoading}
                    noOptionsText={
                      allSurveysLoading ? "Loading..." : "No Data found"
                    }
                    loadingText="Loading..."
                    options={
                      allSurveysLoading
                        ? []
                        : allSurveysError
                        ? []
                        : allSurveys?.data
                    }
                    getOptionLabel={(option) => option?.surveyNumber}
                    onChange={(a, b) => handleMultiSelectMui(a, b, b?._id)}
                    inputValue={inputData?.surveyNumberSearch}
                    onInputChange={(e, inputValue) =>
                      setInputData((prev) => ({
                        ...prev,
                        surveyNumberSearch: inputValue,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...inputData?.surveyNumberSearch}
                        type="string"
                        placeholder={"Enter Survey Number"}
                      />
                    )}
                    sx={{ width: "100%" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Save
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* DELETE MODAL */}
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseModal}
        text={
          selectRows?.length
            ? [...selectRows?.map((data) => data?.groupName)]
            : allSurveyGroups?.data?.map((data) => data?.groupName)
        }
        handleClick={(e) => deleteSurveyGroups(e)}
      />
    </div>
  );
};

export default SurveyGroup;
