import React from "react";
import Skeleton from "../../../components/Skeleton";

const InvoiceGraphLoader = () => {
  return (
    <div className="flex items-center justify-between  h-[80%] mx-5 w-full">
      <div className=" h-[230px] flex flex-col items-start justify-between ">
        <div className="space-y-2">
          <Skeleton className="w-[120px] h-[25px] rounded-md" />
          <Skeleton className="w-[60px] h-[20px] rounded-md" />
        </div>
        <div className="space-y-2">
          <div className="flex items-center gap-3">
            <Skeleton className="w-[35px] h-[25px] rounded-md" />
            <Skeleton className="w-[100px] h-[25px] rounded-md" />
          </div>
          <div className="flex items-center gap-3">
            <Skeleton className="w-[35px] h-[25px] rounded-md" />
            <Skeleton className="w-[100px] h-[25px] rounded-md" />
          </div>
        </div>
      </div>
      <div className="bg-gray-200 w-[230px] h-[230px] rounded-full flex items-center justify-center animate animate-pulse ">
        <div className="w-[140px] h-[140px] rounded-full bg-white"></div>
      </div>
    </div>
  );
};

export default InvoiceGraphLoader;
