import axios from "axios";
import { styled } from "@mui/system";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";

// ! comment this in deployment
// export const BASE_URL = "http://localhost:9701/api/diy";

// ! uncomment this in deployment
export const BASE_URL = "https://apiservice.miratsquanto.com/api/diy";

// do comment this in deployment
export const PUBLISHER_URL =
  "https://api.publisher.miratsquanto.com/api/v1/publisher";
// export const PUBLISHER_URL = "http://localhost:1234/api/v1/publisher";
// ! comment in below code in production
export const tmpToken = getCookie1("diy_token");

export function getCookie1(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result?.toLocaleDateString("en-in", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}
export const getCurrentSurvey = async (surveyId) => {
  let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyId}`);
  // console.log("getCurrentSurvey=>", data.data);
  return data.data;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function calculatePercentageChange(yesterday, today) {
  if (yesterday == 0 && today == 0) {
    return 0;
  } else if (yesterday == 0) {
    return 100;
  }
  let result = ((today - yesterday) / yesterday) * 100;
  return result.toFixed(2);
}

export function generateRandomName() {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "survey-group-";
  for (let i = 0; i < 10; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
export const hideNav = [
  "sign-up",
  "login",
  "DIY",
  "error",
  "verify-change",
  "reset-password",
  "7e08091a73b14e034889265e41ba796f91c766ad",
  "21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42",
  "payment",
  "terms-and-condition",
  "privacy-policy",
  "refund-policy",
  "onboarding",
  "/surveys/",
  "/profile/",
  "/e-invoice/",
  "reconciliation-verification",
  "success",
  "transaction-failure",
];
export function timeDifference(ms) {
  const currentTime = new Date().getTime();
  const difference = currentTime - ms;

  const seconds = 1000;
  const minutes = 60 * seconds;
  const hours = 60 * minutes;
  const days = 24 * hours;
  const weeks = 7 * days;
  const months = 30 * days;
  const years = 365 * days;

  if (difference < minutes) {
    return `${Math.floor(difference / seconds)} s`;
  } else if (difference < hours) {
    return `${Math.floor(difference / minutes)} min`;
  } else if (difference < days) {
    return `${Math.floor(difference / hours)} hrs`;
  } else if (difference < weeks) {
    return `${Math.floor(difference / days)} days`;
  } else if (difference < months) {
    return `${Math.floor(difference / weeks)} weeks`;
  } else if (difference < years) {
    return `${Math.floor(difference / months)} months`;
  } else {
    return `${Math.floor(difference / years)} years`;
  }
}
const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};
export const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        padding: 0.5em;
          font-size: 14px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
     font-size: 14px;
  }

  & .${classes.select}{
    padding: 0.1em 1em;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border: none;
        font-weight: 700;
        font-size: 14px;
        color: #484848;
        outline: none;
  }

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.5rem;
    padding: 0.5em;
    border: none;
  }

  & .${classes.actions} > button {
    padding: 0.1em 1em;
    border: 1px solid #828282;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
  }
  `
);
