import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Slider from "@mui/material/Slider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiWallet2Line } from "react-icons/ri";
import { getCalculation } from "./feasiblityHelperFunction";
import { calculateValue } from "./CalcHelper";
import { IoCashOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineScreenShare, MdManageAccounts } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { GiPublicSpeaker } from "react-icons/gi";
import { FaBuildingNgo } from "react-icons/fa6";
import { Autocomplete, TextField } from "@mui/material";
const Feasibility = () => {
  const { countryData, allLanguages, setSnackbar } = useGlobalContext();
  const [inputData, setInputData] = useState({
    totalCompletes: 0,
    loi: 0,
    ir: 0,
    pii: false,
    advance: "Total",
    countryCode: "US",
    country: "United States",
    language: "English",
  });
  const handelInputChange = (e) => {
    let { name, value } = e.target;

    setInputData((prev) => ({
      ...prev,
      [name]: value >= 0 ? Number(value) : 0,
    }));
  };
  const totalCompletes = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 500,

      label: "500",
    },

    {
      value: 1000,
      label: "> 1000",
    },
  ];
  const LOI = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 30,

      label: "30",
    },

    {
      value: 60,
      label: "60min",
    },
  ];
  const IR = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 50,
      label: "50",
    },

    {
      value: 100,
      label: "< 100%",
    },
  ];
  const advanceTargetAudienceCardData = [
    {
      key: "Total",
      title: "Census Representation",
      desc: "Reach a diverse and representative cross-section of the population for accurate and reliable census data collection.",
      icon: <FaPeopleGroup size={40} />,
    },
    {
      key: "HRDMs",
      title: "Human Resources Decision Makers",
      desc: "Connect with HR professionals who shape workforce strategies and drive organizational growth.",
      icon: <IoIosPeople size={40} />,
    },
    {
      key: "ITDMs",
      title: "IT Decision Makers",
      desc: "Engage with key IT influencers who steer technology adoption and innovation within their organizations.",
      icon: <MdOutlineScreenShare size={40} />,
    },
    {
      key: "Financial/Investment Managers",
      title: "Financial/Investment Managers",
      desc: "Target financial experts and investment managers responsible for critical fiscal decisions and asset management.",
      icon: <IoCashOutline size={40} />,
    },
    {
      key: "Marketing DMs",
      title: "Marketing Decision Makers",
      desc: "Access marketing leaders who design and execute strategies that define brand success and market presence.",
      icon: <GiPublicSpeaker size={40} />,
    },
    {
      key: "C-Level Executives",
      title: "C-Level Execs",
      desc: "Reach top-tier executives with the power to drive corporate strategy and make high-impact decisions.",
      icon: <MdManageAccounts size={40} />,
    },
    {
      key: "A non-profit organization",
      title: "Non Profit Organisation Employee",
      desc: "Engage with professionals dedicated to advancing the mission and impact of non-profit organizations.",
      icon: <FaBuildingNgo size={40} />,
    },
  ];
  function valuetext(value) {
    return `${value}`;
  }
  const handleCPIChange = (e) => {
    const { value } = e.target;
    const { cpiCopy, feasibility = 0, advance, finalCount = 0 } = inputData;
    let feasi = feasibility;
    const diff = Math.floor(value - cpiCopy);

    console.log({
      value,
      cpiCopy,
      sub: diff,
    });
    console.log("percent=>", diff);

    const isTotal = advance?.toLowerCase() === "total";

    const getFeasiChange = (percent, base) => (percent / 100) * base;
    if (value <= 0) {
      setInputData((prev) => ({ ...prev, feasibility: 0, cpi: value }));
      return;
    }
    if (isTotal) {
      if (diff == 0) {
        feasi = finalCount;
      } else if (diff >= 1) {
        if (diff === 1) feasi = getFeasiChange(5, finalCount) + finalCount;
        else if (diff === 2)
          feasi = getFeasiChange(10, finalCount) + finalCount;
        else if (diff === 3)
          feasi = getFeasiChange(15, finalCount) + finalCount;
        else feasi = getFeasiChange(20, finalCount) + finalCount;
      } else if (diff <= -1) {
        if (diff === -1) feasi = finalCount - getFeasiChange(5, finalCount);
        else if (diff === -2)
          feasi = finalCount - getFeasiChange(10, finalCount);
        else if (diff === -3)
          feasi = finalCount - getFeasiChange(15, finalCount);
        else feasi = finalCount - getFeasiChange(20, finalCount);
      }
    } else {
      if (diff == 0) {
        feasi = finalCount;
      } else if (diff >= 2) {
        if (diff === 2) feasi = getFeasiChange(5, finalCount) + finalCount;
        else if (diff === 4)
          feasi = getFeasiChange(10, finalCount) + finalCount;
        else if (diff === 6)
          feasi = getFeasiChange(15, finalCount) + finalCount;
        else if (diff >= 8) feasi = getFeasiChange(20, finalCount) + finalCount;
      } else if (diff <= -2) {
        if (diff === -2) feasi = finalCount - getFeasiChange(5, finalCount);
        else if (diff === -4)
          feasi = finalCount - getFeasiChange(10, finalCount);
        else if (diff === -6)
          feasi = finalCount - getFeasiChange(15, finalCount);
        else if (diff <= -8)
          feasi = finalCount - getFeasiChange(20, finalCount);
      }
    }

    if (feasi === undefined || isNaN(feasi)) {
      feasi = feasibility;
    }

    setInputData((prev) => ({ ...prev, cpi: value, feasibility: feasi }));
  };

  useEffect(() => {
    const { countryCode, advance, country, ir, loi, totalCompletes } =
      inputData;
    if (countryCode && country && advance && ir && loi && totalCompletes) {
      let data = getCalculation(
        inputData?.countryCode,
        inputData?.country,
        inputData?.advance,
        inputData?.ir,
        inputData?.loi,
        inputData?.totalCompletes
      );
      let cpi = calculateValue(
        country,
        Number(loi),
        Number(ir),
        advance == "Total" ? 1 : 2
      );
      // console.log("data=>", data);
      setInputData((prev) => ({
        ...prev,
        fieldWindow: data?.feasibilityDay,
        finalCount: data?.finalCount,
        feasibility: data?.finalCount,
        ratePerDay: data?.ratePerDay,
        cpi: inputData?.pii ? Number(+cpi + 2)?.toFixed(2) : cpi,
        cpiCopy: inputData?.pii ? Number(+cpi + 2)?.toFixed(2) : cpi,
        feasibilityPercent:
          (inputData?.feasibility / inputData?.totalCompletes) * 100 <= 100
            ? (inputData?.feasibility / inputData?.totalCompletes) * 100
            : 100,
      }));
    } else {
      // console.log("inside else");
      setInputData((prev) => ({
        ...prev,
        fieldWindow: 0,
        finalCount: 0,
        feasibility: 0,
        ratePerDay: 0,
        cpi: 0,
        cpiCopy: 0,
        feasibilityPercent: 0,
      }));
    }
  }, [
    inputData?.advance,
    inputData?.country,
    inputData?.countryCode,
    inputData?.ir,
    inputData?.loi,
    inputData?.totalCompletes,
  ]);
  // console.log(calculateValue());
  // console.log("inputData=>", inputData);
  // console.log("countryData", countryData);

  return (
    <div className="w-full flex flex-col gap-[35px] py-6">
      <div className="w-full  ">
        <h1 className="text-[#333] text-[32px] font-[500] inter ">
          Mirats Calculator
        </h1>
        <p className="text-[#333333] font-[500] text-[12px]">
          Please share details about your survey and the criteria for
          participation.
        </p>
      </div>
      <hr />
      <div className="w-full flex items-start relative justify-between gap-[32px]  -py-2">
        <div className="w-[55%] space-y-[32px]">
          <div className="w-full  flex items-center justify-between gap-[18px] ">
            <div className="flex flex-col gap-[10px] w-1/2">
              <label
                htmlFor="Country"
                className="text-[#333] text-[14px] font-[600]"
              >
                Country
              </label>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                className="questionAutoComplete"
                value={inputData?.country}
                options={
                  countryData?.isLoading
                    ? {
                        label: "Loading",
                        value: "",
                      }
                    : countryData?.data?.map((data, ind) => {
                        return {
                          label: data?.name,
                          value: data?.iso2,
                        };
                      })
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, value) => {
                  setInputData((prev) => ({
                    ...prev,
                    country: event.target.outerText,
                    countryCode: value.value,
                  }));
                }}
              /> */}
              <select
                className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] pl-3"
                required
                name="Country"
                value={inputData?.countryCode}
                onChange={(e) => {
                  setInputData((prev) => ({
                    ...prev,
                    country: e.target.options[e.target.selectedIndex].text,
                    countryCode: e.target.value,
                  }));
                }}
              >
                <option value="">Select Country</option>
                {countryData?.isLoading ? (
                  <option value="" disabled>
                    Loading Countries...
                  </option>
                ) : countryData?.error ? (
                  <option value="" disabled>
                    Error while fetching countries try refreshing the page
                  </option>
                ) : (
                  countryData?.data?.map((data, key) => (
                    <option value={data?.iso2} key={key}>
                      {data?.name}
                    </option>
                  ))
                )}
              </select>
            </div>
            <div className="flex flex-col gap-[10px] w-1/2">
              <label
                htmlFor="Language"
                className="text-[#333] text-[14px] font-[600]"
              >
                Language
              </label>
              <select
                className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] pl-3"
                required
                name="language"
                value={inputData?.language}
                onChange={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    language: e.target.value,
                  }))
                }
              >
                <option value="">Select Language</option>
                {allLanguages?.map((data, key) => (
                  <option value={data} key={key}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* total completes */}
          <div className="w-full  space-y-[16px] ">
            <div className="flex items-center justify-between">
              <h3 className="text-[#333333] font-[600] text-[14px]">
                Total Completes
              </h3>
              <input
                type="number"
                name="totalCompletes"
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "+" || e.key === "-") {
                    e.preventDefault();
                  }
                }}
                className="border border-[#E2E2E5] rounded-[4px] pl-3 w-[100px] h-[30px] focus:outline-none text-end px-2 text-[14px]"
                value={inputData?.totalCompletes || ""}
                onChange={handelInputChange}
              />
            </div>
            <div className="w-full  px-1">
              <Slider
                aria-label="Always visible"
                defaultValue={inputData?.totalCompletes}
                getAriaValueText={valuetext}
                marks={totalCompletes}
                valueLabelDisplay="auto"
                max={1000}
                name="totalCompletes"
                value={inputData?.totalCompletes}
                onChange={handelInputChange}
              />
            </div>
          </div>
          {/* LOI */}
          <div className="w-full  space-y-[16px] ">
            <div className="flex items-center justify-between">
              <h3 className="text-[#333333] font-[600] text-[14px]">LOI</h3>
              <div className="border border-[#E2E2E5] rounded-[4px]  w-[100px] h-[30px] focus:outline-none  px-2 text-[14px] flex items-center justify-end gap-1 overflow-hidden">
                <input
                  type="number"
                  value={inputData?.loi || ""}
                  className=" w-[80%] text-end"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  name="loi"
                  onChange={(e) => {
                    if (e.target.value <= 60) {
                      setInputData((prev) => ({
                        ...prev,
                        loi: Number(e.target.value),
                      }));
                    } else {
                      setSnackbar({
                        open: true,
                        msg: "LOI must be less than or equal to 60 mins",
                        severity: "warning",
                      });
                    }
                  }}
                />
                <p>Min</p>
              </div>
            </div>
            <div className="w-full  px-1">
              <Slider
                aria-label="Always visible"
                getAriaValueText={valuetext}
                marks={LOI}
                valueLabelDisplay="auto"
                max={60}
                name="loi"
                value={inputData?.loi}
                onChange={handelInputChange}
              />
            </div>
          </div>
          {/* IR */}
          <div className="w-full  space-y-[16px] ">
            <div className="flex items-center justify-between">
              <h3 className="text-[#333333] font-[600] text-[14px]">IR</h3>
              <div className="border border-[#E2E2E5] rounded-[4px]  w-[100px] h-[30px] focus:outline-none  px-2 text-[14px] flex items-center justify-end gap-1 overflow-hidden">
                <input
                  type="number"
                  className=" w-[80%] text-end"
                  value={inputData?.ir || ""}
                  name="ir"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      ir: e.target.value >= 100 ? 100 : Number(e.target.value),
                    }));
                  }}
                />
                <p>%</p>
              </div>
            </div>
            <div className="w-full  px-1">
              <Slider
                aria-label="Always visible"
                getAriaValueText={valuetext}
                marks={IR}
                valueLabelDisplay="auto"
                max={100}
                name="ir"
                value={inputData?.ir}
                onChange={handelInputChange}
              />
            </div>
          </div>
          {/* PII */}
          <div className="w-full  space-y-[16px] flex items-center justify-between">
            <h3 className="text-[#333333] font-[500] text-[14px]">
              Does your survey collect PII <br /> (Personally Identifiable
              Information)?
            </h3>
            <div className="flex items-center justify-between gap-[16px]">
              <div className="space-x-[8px]">
                <input
                  type="radio"
                  name="pii"
                  id="yes"
                  checked={inputData?.pii}
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      pii: true,
                      cpi: prev?.cpi + 2,
                      cpiCopy: prev?.cpi + 2,
                    }));
                  }}
                  className="accent-black cursor-pointer"
                />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className="space-x-[8px]">
                <input
                  type="radio"
                  name="pii"
                  id="no"
                  checked={!inputData?.pii}
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      pii: false,
                      cpi: prev?.cpi - 2,
                      cpiCopy: prev?.cpi - 2,
                    }));
                  }}
                  className="accent-black"
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          </div>
          {/* accordion */}
          <div>
            <Accordion className="py-4 shadow-none " defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<MdKeyboardArrowDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h1 className="text-[#101828] font-[600] text-[16px]">
                  Advance Settings
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <div className=" p-[23px_24px] grid grid-cols-2 items-center justify-between gap-[16px]">
                  {advanceTargetAudienceCardData?.map((d, i) => (
                    <div
                      key={i}
                      className={` border ${
                        inputData?.advance == d?.key
                          ? "border-2 border-blue-400"
                          : ""
                      } p-[24px] rounded-[4px] space-y-[24px] cursor-pointer`}
                      onClick={(e) =>
                        setInputData((prev) => ({ ...prev, advance: d?.key }))
                      }
                    >
                      {d?.icon}
                      <div className="space-y-[8px]">
                        <h1 className="text-[14px] font-[600] text-[#101828]">
                          {d?.title}
                        </h1>
                        <p className="text-[#667085] text-[12px] font-[400]">
                          {d?.desc}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="w-[45%]  sticky top-[100px] bg-[#F9FAFB] p-[18px] space-y-[32px]">
          <div className="pb-[12px] border-b">
            <h1 className="text-[14px] font-[700] text-[#101828]">
              Feasibility & Cost
            </h1>
            <p className="text-[12px] font-[400] text-[#667085]">
              Estimated feasibility and cost based on values you filled in the
              adjacent form.
            </p>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-[#333333] font-[600] text-[14px]">
              Estimated CPC (Cost Per Complete)
            </h2>
            <input
              type="number"
              className="border border-[#E2E2E5] rounded-[4px]  text-[14px] pl-3 w-[100px] h-[30px]"
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "+" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              onChange={handleCPIChange}
              value={inputData?.totalCompletes == 0 ? 0 : inputData?.cpi}
            />
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-[#333333] font-[600] text-[14px]">
              Field Window (In Days)
            </h2>
            <input
              type="number"
              className="border border-[#E2E2E5] rounded-[4px]  text-[14px] pl-3 w-[100px] h-[30px]"
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "+" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              value={
                inputData?.totalCompletes == 0 ? 0 : inputData?.fieldWindow
              }
              onChange={(e) => {
                let { value } = e.target;
                setInputData((prev) => ({
                  ...prev,
                  fieldWindow: Number(value),
                  feasibility:
                    prev?.ratePerDay * Number(value) > prev?.finalCount
                      ? prev?.finalCount
                      : prev?.ratePerDay * value,
                }));
              }}
            />
          </div>
          <div className="space-y-[12px]">
            <div className="bg-[#FFFFFF] p-[24px] space-y-[16px] border rounded-[4px] border-[#EAECF0]">
              <div className="flex items-center justify-between">
                <h3 className="text-[14px] font-[600] text-[#101828]">
                  Feasibility
                </h3>
                <h4 className="text-[14px] font-[600] text-[#101828]">
                  {inputData?.totalCompletes == 0
                    ? 0
                    : (inputData?.feasibility || 0) >=
                      (inputData?.totalCompletes || 0)
                    ? inputData?.totalCompletes
                    : Math.ceil(inputData?.feasibility || 0)}
                  /{inputData?.totalCompletes}
                </h4>
              </div>
              <div className="h-[14px] w-full rounded-[20px] bg-[#D1E9FF] overflow-hidden">
                <div
                  className={`h-full rounded-[20px] bg-primary/70 transition-all duration-500`}
                  style={{
                    width: `${
                      inputData?.totalCompletes == 0
                        ? 0
                        : Math.ceil(
                            (inputData?.feasibility /
                              inputData?.totalCompletes) *
                              100
                          )
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="bg-[#FFFFFF] p-[24px] space-y-[16px] border rounded-[4px] border-[#EAECF0]">
              <div className="flex items-center justify-between">
                <RiWallet2Line size={40} />
                <h2 className="text-[#101828] font-[600] text-[20px]">
                  $
                  {Math.ceil(
                    inputData?.feasibility > 0
                      ? inputData?.feasibility >= inputData?.totalCompletes
                        ? inputData?.cpi * inputData?.totalCompletes
                        : inputData?.cpi * inputData?.feasibility
                      : 0
                  )}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feasibility;
