import React from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, getCurrentSurvey } from "../Utlils";
import axios from "axios";

const EncryptStart = () => {
  const { surveyId, encSupplierId, orgId } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { r, t, tk, p, org, source_id, utm_id } = Object.fromEntries(
    urlSearchParams.entries()
  );
  console.log(r, t, tk, p, org, source_id, utm_id);
  // console.log(surveyId, encSupplierId);
  getCurrentSurvey(surveyId)
    .then(async (survey) => {
      if (survey === undefined) {
        console.log("survey not found");
        return;
      }
      if (t == 0) {
        if (orgId === "u5Ea5JK84TlLXLkSuMx5JYqIS") {
          // console.log("inside lucid session count ");
          await axios
            .get(`${BASE_URL}/session/lucid-session-count/${orgId}`)
            .then((res) => {
              let { data: lucidCountRes } = res;
              // console.log(
              //   "got response from api=>",
              //   res,
              //   lucidCountRes?.success,
              //   lucidCountRes?.redirect
              // );
              if (lucidCountRes?.success && !lucidCountRes?.redirect) {
                window.location.href = `${window.location.origin}/error/429`;
                return;
              }
            });
        }
        let originalUrl = `${window.location.origin}/DIY/${
          survey?._id
        }/${encSupplierId}/${orgId}/lightningStart?SRCID=${encSupplierId}&RID=${
          tk ? tk : r
        }${p ? `&p=${p}` : ""}${org ? `&org=${org}` : ""}${
          source_id ? `&source_id=${source_id}` : ""
        }${utm_id ? `&utm_id=${utm_id}` : ""}`;
        window.location.href = originalUrl;
      } else {
        let originalUrl = `${window.location.origin}/DIY/${
          survey?._id
        }/${encSupplierId}/${orgId}/lightningStart?SRCID=${encSupplierId}&RID=${
          tk ? tk : r
        }&gamma=alpha${p ? `&p=${p}` : ""}${org ? `&org=${org}` : ""}${
          source_id ? `&source_id=${source_id}` : ""
        }${utm_id ? `&utm_id=${utm_id}` : ""}`;
        window.location.href = originalUrl;
      }
    })
    .catch((err) => {
      console.log("something went wrong in url");
    });
  return (
    <div className="flex items-center justify-center h-screen">
      <h1>Loading...</h1>
    </div>
  );
};

export default EncryptStart;
