import React, { useEffect, useState } from "react";
import SurveySidebar from "./components/SurveySidebar";
import Details from "./components/Details";
import { useNavigate, useParams } from "react-router-dom";
import Qualification from "./components/Qualification";
import Quotas from "./components/Quotas";
import Allocations from "./components/Allocations";
import Reports from "./components/Reports";
import DetailHeader from "./components/DetailHeader";
import { BASE_URL } from "../../../Utlils";
import axios from "axios";
import Reconciliation from "./components/Reconciliation";
import Security from "./components/Security";
import Documents from "./components/Documents";
import RespondentAnswers from "./components/RespondentAnswers";
import TerminationData from "./components/TerminationData";

const UnknownPage = ({ id }) => {
  // navigate(`/surveys/${id}/details`);
  window.location.href = `${window.location.origin}/surveys/${id}/details`;
  return <h1>Page Not Found</h1>;
};
const SurveyDetails = () => {
  const { type, id } = useParams();
  const [singleSurvey, setSingleSurvey] = useState({});
  const [singleSurveyStats, setSingleSurveyStats] = useState({});
  const [qualificationTable, setQualificationTable] = useState([]);
  const getSingleSurvey = async () => {
    try {
      let data = await axios.get(`${BASE_URL}/survey/one?id=${id}`);
      setSingleSurvey(data.data);
    } catch (error) {
      setSingleSurvey({});
    }
  };
  const getSingleSurveyStats = async () => {
    try {
      let data = await axios.get(
        `${BASE_URL}/session/get-single-survey-stats?sId=${id}`
      );
      setSingleSurveyStats(data.data);
    } catch (error) {
      setSingleSurveyStats({});
    }
  };
  const getTableQualification = async (e) => {
    try {
      setQualificationTable([]);
      let data = (await axios.get(`${BASE_URL}/session/${id}/qualifications`))
        .data;
      setQualificationTable(data);
    } catch (error) {
      setQualificationTable([]);
    }
  };
  useEffect(() => {
    getSingleSurvey();
    getSingleSurveyStats();
    getTableQualification();
  }, [id]);

  // console.log(singleSurvey);
  // console.log("id=>", id);

  const renderComponent = () => {
    switch (type) {
      case "details":
        return (
          <Details
            data={singleSurvey}
            getSingleSurvey={getSingleSurvey}
            singleSurveyStats={singleSurveyStats}
          />
        );
      case "qualification":
        return (
          <Qualification
            data={singleSurvey}
            type={type}
            id={id}
            getSingleSurvey={getSingleSurvey}
            qualificationTable={qualificationTable}
            getTableQualification={getTableQualification}
          />
        );
      case "quotas":
        return (
          <Quotas
            data={singleSurvey}
            type={type}
            id={id}
            getSingleSurvey={getSingleSurvey}
            qualificationTable={qualificationTable}
            getTableQualification={getTableQualification}
          />
        );
      case "allocations":
        return (
          <Allocations
            data={singleSurvey}
            type={type}
            id={id}
            getSingleSurvey={getSingleSurvey}
          />
        );
      case "reports":
        return (
          <Reports
            data={singleSurvey}
            id={id}
            singleSurveyStats={singleSurveyStats}
          />
        );
      case "reconciliation":
        return (
          <Reconciliation
            data={singleSurvey}
            id={id}
            singleSurveyStats={singleSurveyStats}
            getSingleSurvey={getSingleSurvey}
          />
        );
      case "security":
        return (
          <Security
            data={singleSurvey}
            id={id}
            getSingleSurvey={getSingleSurvey}
          />
        );
      case "documents":
        return <Documents data={singleSurvey} id={id} />;
      case "respondent-answers":
        return (
          <RespondentAnswers
            data={singleSurvey}
            id={id}
            qualificationTable={qualificationTable}
          />
        );
      case "termination-data":
        return <TerminationData data={singleSurvey} id={id} />;
      default:
        return <UnknownPage id={id} />;
    }
  };
  return (
    <SurveySidebar>
      <div className="flex flex-col w-full gap-[48px] ">
        <DetailHeader
          data={singleSurvey}
          getSingleSurvey={getSingleSurvey}
          singleSurveyStats={singleSurveyStats}
        />
        {renderComponent()}
      </div>
    </SurveySidebar>
  );
};

export default SurveyDetails;
