import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL, CustomTablePagination } from "../../../../Utlils";
import { getStatsDetail } from "./helperData";
import TableLoader from "./TableLoader";
import NoData from "../../../../components/NoData";
import { useQuery } from "@tanstack/react-query";

let dummyfilterData = [
  { label: "All", value: "all" },
  { label: "Completes", value: "10" },
  { label: "Over Quota", value: "40" },
  { label: "Prescreens", value: "1" },
  { label: "Security Drops", value: [36, 30, 38, 35] },
  { label: "Terminates", value: [20, 30] },
];

const RespondentAnswers = ({ data, qualificationTable }) => {
  const { setSnackbar } = useGlobalContext();

  const { id } = useParams();
  const [tab, setTab] = useState(1);
  const [filters, setFilters] = useState({
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3,
      new Date().getDate()
    )?.toLocaleDateString("en-CA"),

    endDate: new Date().toLocaleDateString("en-CA"),
  });
  const [tempFilters, setTempFilters] = useState(filters);

  //GET SURVEY SESSIONS:
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const {
    data: surveySessions,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getSurveySessions(),
    queryKey: [
      `${id}-respondentAnswers-${filters?.startDate}-${filters?.endDate}-${tab}-${page}-${rowsPerPage}`,
    ],
    staleTime: 10000,
    cacheTime: 15000,
  });
  const getSurveySessions = async () => {
    try {
      const sessionData = await axios.get(
        `${BASE_URL}/session/get-survey-sessions?surveyID=${id}&startDate=${
          filters.startDate
        }&endDate=${filters.endDate}&tab=${tab}&page=${
          Number(page) + 1
        }&rows=${rowsPerPage}`
      );
      return sessionData?.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  // console.log(tab);

  //DOWNLOAD TO EXCEL:
  const DownloadToExcel = () => {
    var elt = document.getElementById("table-to-xls-DATA");
    var wb = utils.table_to_book(elt, { sheet: "MiratsQuanto" });
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);
    const fieldsToRemove = ["Supplier Account"];
    const filteredData = jsonData.map((row) => {
      fieldsToRemove.forEach((field) => delete row[field]);
      return row;
    });
    const newWorksheet = utils.json_to_sheet(filteredData);
    const newWorkbook = utils.book_new();
    utils.book_append_sheet(newWorkbook, newWorksheet, "MiratsQuanto");
    writeFile(
      newWorkbook,
      `Mirats Quanto - SN#${data?.surveyNumber} - Resp Answers.xlsx`
    );
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  // pagination function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const refetchSurveySessionsData = (start, end) => {
    setFilters({ startDate: start, endDate: end });
  };
  // console.log("qualificationTable=>", qualificationTable);
  // console.log({ surveySessions, isError, isLoading });
  // console.log(filters);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <div className="flex flex-col gap-[26px]">
          <h1 className="text-[#000] text-[18px] font-[500] inter">
            Field Date
          </h1>
          <div className="flex items-center gap-[28px]">
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={tempFilters?.startDate}
              onChange={(e) => {
                setTempFilters((prev) => ({
                  ...prev,
                  startDate: e.target.value,
                }));
              }}
            />
            <p className="text-[#333] text-[16px] font-[400] inter">To</p>
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={tempFilters?.endDate}
              onChange={(e) => {
                setTempFilters((prev) => ({
                  ...prev,
                  endDate: e.target.value,
                }));
              }}
            />
            <button
              className="border border-[#0226BE] text-[#0226BE] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]  cursor-pointer"
              onClick={(e) => setFilters(tempFilters)}
            >
              Search
            </button>
            <p
              className="text-[14px] underline underline-offset-4 cursor-pointer"
              onClick={(e) => {
                const start = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - 3,
                  new Date().getDate()
                )?.toLocaleDateString("en-CA");
                const end = new Date().toLocaleDateString("en-CA");

                if (
                  tempFilters?.startDate !== start ||
                  tempFilters?.endDate !== end
                ) {
                  setTempFilters({
                    startDate: start,
                    endDate: end,
                  });
                  refetchSurveySessionsData(start, end);
                }
              }}
            >
              Clear filters
            </p>
          </div>
        </div>
        <button
          className={`${
            surveySessions?.surveySessions?.length
              ? "bg-[#0226BE] cursor-pointer"
              : "bg-blue-400 cursor-not-allowed"
          }  text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px] btnShadow `}
          disabled={!surveySessions?.surveySessions?.length}
          onClick={(e) => DownloadToExcel()}
        >
          Export To Excel
        </button>
      </div>
      {/* table */}
      <div>
        {/* tabs */}
        <div className="w-full tableBorder flex items-center justify-between px-10 py-3 mt-[5px]">
          {dummyfilterData?.map((data, ind) => (
            <h1
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
                setRowsPerPage(50);
                setPage(0);
              }}
            >
              {data?.label}{" "}
            </h1>
          ))}
        </div>
        <div className="w-full tableBorder flex flex-col">
          {isLoading ? (
            <TableLoader />
          ) : isError ? (
            <div className="w-full  h-[200px] flex items-center justify-center">
              <h1 className="text-[#666666]">Something went wrong </h1>
            </div>
          ) : (
            <div
              className={`${
                surveySessions?.surveySessions?.length
                  ? "overflow-x-auto"
                  : "overflow-x-hidden"
              } w-full  containerScroll`}
            >
              <table
                className="whitespace-nowrap text-left w-full"
                id="table-to-xls-DATA"
              >
                <thead className=" h-[45px] ">
                  <tr className="py-3">
                    {[
                      "Ref ID",
                      "Supplier Account",
                      "Survey No.",
                      "Project No.",
                      "Status",
                      "Client Status",
                      // "IP",
                      "Browser",
                      "Device Type",
                      "Start Time",
                      "End Time",
                      "Timezone",
                      "Total Time",
                      "CPI",
                    ]?.map((d) => (
                      <td className="text-[#666] text-[14px] font-[600] pl-4">
                        {d}
                      </td>
                    ))}
                    {qualificationTable
                      ?.filter((d) => d?.status)
                      ?.map((data) => {
                        return (
                          <td className="text-[#666] text-[14px] font-[600] pl-4">
                            {data?.question?.questionName}
                          </td>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {surveySessions?.surveySessions?.length ? (
                    surveySessions?.surveySessions?.map((da) => (
                      <tr className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] ">
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.ref_id}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {/* {da?.supplierAccountId?.supplierName} */}
                          Mirats Insights Panel
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {data?.surveyNumber}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {data?.projectNumber}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {getStatsDetail("mirats", da?.mirats_status)} (
                          {da?.mirats_status})
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {getStatsDetail("client", da?.client_status)} (
                          {da?.client_status})
                        </td>
                        {/* <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                        {da?.geoData?.ip}
                      </td> */}

                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.sessionTechnicalDetails?.browserName
                            ? da?.sessionTechnicalDetails?.browserName
                            : "-"}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.sessionTechnicalDetails?.os
                            ? da?.sessionTechnicalDetails?.os
                            : "-"}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {new Date(
                            da?.survey_start_time
                          )?.toLocaleDateString() +
                            " " +
                            new Date(
                              da?.survey_start_time
                            )?.toLocaleTimeString()}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.survey_end_time
                            ? new Date(
                                da?.survey_end_time
                              )?.toLocaleDateString() +
                              " " +
                              new Date(
                                da?.survey_end_time
                              )?.toLocaleTimeString()
                            : "-"}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.geoData?.time_zone?.name}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {da?.total_survey_time}
                        </td>
                        <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                          {Number(da?.surveyCPI)?.toFixed(2) || 0}
                        </td>

                        {qualificationTable?.map((data) => {
                          const response = da?.responses?.find(
                            (res) => res?.question_id == data?._id
                          );
                          if (typeof response?.user_response == "string") {
                            return (
                              <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                                {response?.user_response}
                              </td>
                            );
                          } else {
                            return (
                              <td className="px-3 py-6 text-[#667085] font-[400] text-[12px] ">
                                {response?.user_response?.map((ur) => {
                                  return <p>{ur}</p>;
                                })}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))
                  ) : (
                    <td colSpan={12}>
                      <div className="py-10">
                        <NoData
                          msg={
                            <div className="flex items-center gap-[10px]">
                              <h1>Try Changing the filters</h1>
                              {/* <button
                    className="py-1 px-4 bg-primary text-[#fff] rounded-md font-[500]"
                    onClick={(e) => console.log("hehe")}
                  >
                    Clear
                  </button> */}
                            </div>
                          }
                        />
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          )}

          <CustomTablePagination
            rowsPerPageOptions={[10, 25, , 50, 100]}
            sx={{ fontSize: "10px" }}
            count={surveySessions?.pagination?.totalRecords || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default RespondentAnswers;
